/* Footer */
footer {
  height: 10vh;
  box-shadow: 3px 0 8px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
}
p {
  position: relative;
  top: 20%;
  bottom: 25%;
  text-align: center;
}
