@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;600;700&display=swap");

:root {
  --font-family: "Quicksand", sans-serf;
  --normal-font: 400;
  --bold-font: 700;
  --bolder-font: 900;
  /* ===== Font colors ===== */
  --orange-1: #e36c0a;
  --brown-1: #943634;
  --blue-1: #365f91;
  --white-1: #ffffff;
  --primary-color: #943634;
  --secondary-color: #ff7235;
  --primary-shadow: #8b8eaf;
  --secondary-shadow: #a17a69;
}

/* CSS Resets */
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
  /* outline: 1px solid red; */
}

ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}

/* Common styles */

body {
  font-family: var(--font-family);
  font-size: var(--normal-font);
  line-height: 1.5;
}

.topic-heading {
  font-size: 2.5rem;
  font-variant: small-caps;
  text-align: center;
  color: var(--blue-1);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.rainbow-text {
  background: linear-gradient(
    to right,
    #ef5350,
    #f48fb1,
    #7e57c2,
    #2196f3,
    #26c6da,
    #43a047,
    #eeff41,
    #f9a825,
    #ff5722
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
