/* Header */
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header > * {
  flex-basis: 50%;
}

.header-image {
  max-height: 80%;
  max-width: 80%;
  object-fit: contain;
  margin-top: 1rem;
  margin-left: 5rem;
}
figcaption {
  visibility: hidden;
}
.header-intro {
  margin: 0rem 1rem 0 1rem;
}
.header-intro h1 {
  font-size: 2rem;
}
.header-intro strong {
  font-size: 4rem;
  line-height: 1;
}
.header-social-logos {
  margin-top: 0.5rem;
}
.header-social-logos *:hover {
  transform: scale(1.2);
}

@media screen and (min-width: 800px) {
  .header {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
  }
  .header-image-container {
    -webkit-order: 1;
    order: 1;
  }
  .header-image {
    height: 85%;
    width: 85%;
    object-fit: contain;
    position: relative;
    right: 12%;
    /* justify-content: start; */
  }
  .header-intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    left: 8%;
  }
  .header-intro strong {
    font-size: 3rem;
    line-height: 1;
  }
}

@media screen and (max-width: 420px) {
  .header-intro h1 {
    font-size: 1.5rem;
  }
  .header-intro strong {
    font-size: 2.5rem;
    line-height: 1;
  }
  .header-image {
    max-height: 110%;
    max-width: 110%;
  }
}
